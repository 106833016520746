<template>
  <div
    class="absolute left-0 top-0 -z-10 h-full w-full transition-all duration-1000"
    :class="isVisible ? 'opacity-100' : 'opacity-0'"
    style="background: url(/images/welcome-dorito.webp)"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'

const { isCCMember } = storeToRefs(useSwapkitWalletStore())

const isVisible = ref(false)
onMounted(() => {
  isVisible.value = !!isCCMember.value
  setTimeout(() => {
    isVisible.value = false
  }, 10000)
})
</script>
